import Vue from 'vue'
import Vuex from 'vuex'

import app from '../main'
import user from './modules/user'
import profileManager from './modules/profileManager'
import wallet from './modules/wallet'
import requests from './modules/requests'
import reporting from './modules/reporting'

Vue.use( Vuex )

export default new Vuex.Store( {
  state: {
    currentCountry: localStorage.getItem( 'currentCountry' ) != null ?
      localStorage.getItem( 'currentCountry' ) : {},
  },
  mutations: {
    changeLang( state, payload ) {
      app.$i18n.locale = payload
      localStorage.setItem( 'currentLanguage', payload )
    },
    changeCountry( state, payload ) {
      state.currentCountry = payload
      localStorage.setItem( 'currentCountry', payload )
    }
  },
  getters: {
    getCurrentCountry: state => {
      return JSON.parse( state.currentCountry );
    },
  },
  actions: {
    setLang( {
      commit
    }, payload ) {
      commit( 'changeLang', payload )
    },
    setCountry( {
      commit
    }, payload ) {
      commit( 'changeCountry', payload )
    }
  },
  modules: {
    user,
    profileManager,
    requests,
    wallet,
    reporting,
  }
} )