export const defaultMenuType = 'menu-default' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const defaultStartPath = '/app/dashboard/Home'
export const searchPath = '#'
export const buyUrl = 'https://1.envato.market/nEyZa'

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

export const defaultLocale = 'en'
export const defaultDirection = 'ltr'
export const localeOptions = [{
	id: 'en',
	name: 'English LTR',
	direction: 'ltr'
},
{
	id: 'es',
	name: 'Español',
	direction: 'ltr'
},
{
	id: 'enrtl',
	name: 'English RTL',
	direction: 'rtl'
}
]

export const firebaseConfig = {
	apiKey: "AIzaSyCZ7l6TsUKXhchC4yvKEdlMvMN2hHe4ams",
	authDomain: "yellowbird-967b2.firebaseapp.com",
	databaseURL: "https://yellowbird-967b2.firebaseio.com",
	projectId: "yellowbird-967b2",
	storageBucket: "yellowbird-967b2.appspot.com",
	messagingSenderId: "126174982841",
	appId: "1:126174982841:web:e77dbf38686f06b1112168",
	measurementId: "G-YGPKGQT9FE"

};

export const getFirstWeekOfMonth = (date) => {
	let weekStart = date.getDate() - date.getDay() + 1;
	return weekStart;
}

export const getMondayOfWeek = (date) => {
	let day = date.getDay();
	let diffToMonday = date.getDate() - day + (day === 0 ? -6 : 1);
	let monday = new Date(date.setDate(diffToMonday));
	return monday;
}

export const apiUrl = 'https://api.coloredstrategies.com'

export const currentUser = {
	// id: 1,
	// displayName: 'Wagaana Alex',
	// photoURL: '/assets/img/profile-pic-l.jpg',
	date: 'Last seen today 15:24'
};

export const getDateByDays = (numberOfDays) => {
	return new Date(
		new Date().getTime() - 60 * 60 * 24 * numberOfDays * 1000
	);
};

export const getDateByWeeks = (numberOfWeeks) => {
	return getDateByDays(numberOfWeeks * 14);
};

export const getDateByMonths = (numberOfMonths) => {
	let now = new Date();
	let firstDate = new Date(now.getFullYear(), now.getMonth() - numberOfMonths, 1);
	return firstDate;
};

export const isDemo = false
export const themeRadiusStorageKey = 'theme_radius'
export const defaultColor = 'light.orange'
export const colors = [
	'light.purple',
	'dark.purple',
	'light.blue',
	'dark.blue',
	'light.green',
	'dark.green',
	'light.orange',
	'dark.orange',
	'light.red',
	'dark.red'
]

export const getDirection = () => {
	let direction = defaultDirection
	if (localStorage.getItem('direction')) {
		const localValue = localStorage.getItem('direction')
		if (localValue === 'rtl' || localValue === 'ltr') {
			direction = localValue
		}
	}
	return {
		direction,
		isRtl: direction === 'rtl'
	}
}

export const campaignOffers = [{
	id: "email",
	value: "email",
	text: "Email Messaging"
},
{
	id: "sms",
	value: "sms",
	text: "SMS Messaging"
},
{
	id: "firebaseNotifications",
	value: "firebaseNotifications",
	text: "Firebase Messaging"
},
{
	id: "whatappMessaging",
	value: "whatappMessaging",
	text: "Whatsapp Messaging"
},
{
	id: "telegramMessaging",
	value: "telegramMessaging",
	text: "Telegram Messaging"
}
]

export const queryParameters = {
	campaignTables: [{
		id: "users",
		value: "users",
		text: "Users Table"
	},
	{
		id: "freelancers",
		value: "freelancers",
		text: "Freelancers Table"
	}
	],
	tableMappings: [{
		id: "accountCreationTime",
		value: "accountCreationTime",
		text: "Account Creation Time",
		table: "users",
		dataType: "number"
	}, {
		id: "gender",
		value: "gender",
		text: "Gender",
		table: "users",
		dataType: "string"
	}, {
		id: "lastUpdateTime",
		value: "lastUpdateTime",
		text: "Last Update Time",
		table: "freelancers",
		dataType: "number"
	}, {
		id: "isAvailable",
		value: "isAvailable",
		text: "Availability",
		table: "freelancers",
		dataType: "boolean"
	}],
	mappingConditions: [{
		id: "equalTo",
		value: "===",
		text: "Equal To"
	}, {
		id: "greaterThan",
		value: ">",
		text: "Greater Than"
	}, {
		id: "lessThan",
		value: "<",
		text: "Less Than"
	}],
	conditionAnswers: [{
		id: "available",
		value: true,
		text: "Available",
		mappingId: "isAvailable"
	}, {
		id: "notAvailable",
		value: false,
		text: "Not Available",
		mappingId: "isAvailable"
	}, {
		id: "male",
		value: "Male",
		text: "Male",
		mappingId: "gender"
	}, {
		id: "female",
		value: "Female",
		text: "Female",
		mappingId: "gender"
	}, {
		id: "today_1",
		value: "today",
		text: "Today",
		mappingId: "accountCreationTime"
	}, {
		id: "today_2",
		value: "today",
		text: "Today",
		mappingId: "accountCreationTime"
	}, {
		id: "yesterday_1",
		value: "yesterday",
		text: "Yesterday",
		mappingId: "accountCreationTime"
	}, {
		id: "thisWeek_1",
		value: "ThisWeek",
		text: "This Week",
		mappingId: "accountCreationTime"
	}, {
		id: "lastWeek_1",
		value: "LastWeek",
		text: "Last Week",
		mappingId: "accountCreationTime"
	}, {
		id: "thisMonth_1",
		value: "ThisMonth",
		text: "This Month",
		mappingId: "accountCreationTime"
	}, {
		id: "lastMonth_1",
		value: "LastMonth",
		text: "Last Month",
		mappingId: "accountCreationTime"
	}, {
		id: "thisYear_1",
		value: "ThisYear",
		text: "This Year",
		mappingId: "accountCreationTime"
	}, {
		id: "lastYear_1",
		value: "LastYear",
		text: "Last Year",
		mappingId: "accountCreationTime"
	}, {
		id: "today_2",
		value: "today",
		text: "Today",
		mappingId: "lastUpdateTime"
	}, {
		id: "today_3",
		value: "today",
		text: "Today",
		mappingId: "lastUpdateTime"
	}, {
		id: "yesterday_2",
		value: "yesterday",
		text: "Yesterday",
		mappingId: "lastUpdateTime"
	}, {
		id: "thisWeek_2",
		value: "ThisWeek",
		text: "This Week",
		mappingId: "lastUpdateTime"
	}, {
		id: "lastWeek_2",
		value: "LastWeek",
		text: "Last Week",
		mappingId: "lastUpdateTime"
	}, {
		id: "thisMonth_2",
		value: "ThisMonth",
		text: "This Month",
		mappingId: "lastUpdateTime"
	}, {
		id: "lastMonth_2",
		value: "LastMonth",
		text: "Last Month",
		mappingId: "lastUpdateTime"
	}, {
		id: "thisYear_2",
		value: "ThisYear",
		text: "This Year",
		mappingId: "lastUpdateTime"
	}, {
		id: "lastYear_2",
		value: "LastYear",
		text: "Last Year",
		mappingId: "lastUpdateTime"
	}]
}

export const campaignScheduleOptions = [{
	id: "DAILY",
	value: "DAILY",
	text: "Run Daily"
}, {
	id: "WEEKLY",
	value: "WEEKLY",
	text: "Run Weekly"
}, {
	id: "MONTHLY",
	value: "MONTHLY",
	text: "Run Monthly"
},]

export const emailTemplates = {
	parameters: [{
		id: "GREATING",
		label: "GREATING",
		value: "Title",
		inputType: "text"
	}, {
		id: "EMAIL_BODY",
		label: "BODY",
		value: "Hello All",
		inputType: "text"
	}, {
		id: "PROFILE_LINK",
		label: "Button Dinamic Link",
		value: "https://yellowbird.mobi",
		inputType: "string"
	}, {
		id: "BUTTON_LABEL",
		label: "Button Label",
		value: "Open Website",
		inputType: "string"
	}, {
		id: "COMPANY_ABOUT",
		label: "COMPANY ABOUT",
		value: "GroupNGS has an extended network of specialists who can be staffed on a client’s project team to address specific needs or solve issues.",
		inputType: "text"
	}, {
		id: "COMPANY_ADDRESS",
		label: "COMPANY ADDRESS",
		value: "Plot 147 Mutesa II Rd, Kampala, Uganda",
		inputType: "text"
	}, {
		id: "COMPANY_NAME",
		label: "COMPANY NAME",
		value: "GroupNGS",
		inputType: "string"
	}, {
		id: "COMPANY_PHONE",
		label: "COMPANY PHONE",
		value: "+256-707-716-811",
		inputType: "string"
	}, {
		id: "FROM_ADDRESS",
		label: "FROM ADDRESS",
		value: "contact@groupngs.com",
		inputType: "string"
	}, {
		id: "REPLY_TO_ADDRESS",
		label: "REPLY TO ADDRESS",
		value: "contact@groupngs.com",
		inputType: "string"
	}, {
		id: "UNSUBSCRIBE_URL",
		label: "UNSUBSCRIBE URL",
		value: "%unsubscribe_url%",
		inputType: "string"
	}],
	id: "PLAIN_MESSAGE",
	creator: "alex.wagaana@yellowbird.mobi",
	subject: "GroupNGS Products Update",
	creationTimemillis: 39048390483,
	text: "Plain Template",
	emailLink: "https://groupngs.blogspot.com/",
	html: `<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
	<meta charset="utf-8"> <!-- utf-8 works for most cases -->
	<meta name="viewport" content="width=device-width"> <!-- Forcing initial-scale shouldn/'t be necessary -->
	<meta http-equiv="X-UA-Compatible" content="IE=edge"> <!-- Use the latest (edge) version of IE rendering engine -->
	<meta name="x-apple-disable-message-reformatting"> <!-- Disable auto-scale in iOS 10 Mail entirely -->
	<title>{COMPANY_NAME}</title> <!-- The title tag shows in email notifications, like Android 4.4. -->

	<link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700" rel="stylesheet">

	<style>
		/* What it does: Remove spaces around the email design added by some email clients. */
		/* Beware: It can remove the padding / margin and add a background color to the compose a reply window. */
		html,
		body {
			margin: 0 auto !important;
			padding: 0 !important;
			height: 100% !important;
			width: 100% !important;
			background: #f1f1f1;
		}

		/* What it does: Stops email clients resizing small text. */
		* {
			-ms-text-size-adjust: 100%;
			-webkit-text-size-adjust: 100%;
		}

		/* What it does: Centers email on Android 4.4 */
		div[style*="margin: 16px 0"] {
			margin: 0 !important;
		}

		/* What it does: Stops Outlook from adding extra spacing to tables. */
		table,
		td {
			mso-table-lspace: 0pt !important;
			mso-table-rspace: 0pt !important;
		}

		/* What it does: Fixes webkit padding issue. */
		table {
			border-spacing: 0 !important;
			border-collapse: collapse !important;
			table-layout: fixed !important;
			margin: 0 auto !important;
		}

		/* What it does: Uses a better rendering method when resizing images in IE. */
		img {
			-ms-interpolation-mode: bicubic;
		}

		/* What it does: Prevents Windows 10 Mail from underlining links despite inline CSS. Styles for underlined links should be inline. */
		a {
			text-decoration: none;
		}

		/* What it does: A work-around for email clients meddling in triggered links. */
		*[x-apple-data-detectors],
		/* iOS */
		.unstyle-auto-detected-links *,
		.aBn {
			border-bottom: 0 !important;
			cursor: default !important;
			color: inherit !important;
			text-decoration: none !important;
			font-size: inherit !important;
			font-family: inherit !important;
			font-weight: inherit !important;
			line-height: inherit !important;
		}

		/* What it does: Prevents Gmail from displaying a download button on large, non-linked images. */
		.a6S {
			display: none !important;
			opacity: 0.01 !important;
		}

		/* What it does: Prevents Gmail from changing the text color in conversation threads. */
		.im {
			color: inherit !important;
		}

		/* If the above doesn't work, add a .g-img class to any image in question. */
		img.g-img+div {
			display: none !important;
		}

		/* What it does: Removes right gutter in Gmail iOS app: https://github.com/TedGoas/Cerberus/issues/89  */
		/* Create one of these media queries for each additional viewport size you'd like to fix */

		/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
		@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
			u~div .email-container {
				min-width: 320px !important;
			}
		}

		/* iPhone 6, 6S, 7, 8, and X */
		@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
			u~div .email-container {
				min-width: 375px !important;
			}
		}

		/* iPhone 6+, 7+, and 8+ */
		@media only screen and (min-device-width: 414px) {
			u~div .email-container {
				min-width: 414px !important;
			}
		}

		.primary {
			background: #109518;
		}

		.bg_white {
			background: #ffffff;
		}

		.m_bg_light {
			background: #f7fafa;
		}

		.bg_black {
			background: #000000;
		}

		.bg_dark {
			background: rgba(0, 0, 0, .8);
		}

		.email-section {
			padding: 2.5em;
		}

		/*BUTTON*/
		.btn {
			padding: 10px 15px;
			display: inline-block;
		}

		.btn.btn-primary {
			border-radius: 5px;
			background: #109518;
			color: #ffffff;
		}

		.btn.btn-white {
			border-radius: 5px;
			background: #ffffff;
			color: #000000;
		}

		.btn.btn-white-outline {
			border-radius: 5px;
			background: transparent;
			border: 1px solid #fff;
			color: #fff;
		}

		.btn.btn-black-outline {
			border-radius: 0px;
			background: transparent;
			border: 2px solid #000;
			color: #000;
			font-weight: 700;
		}

		.btn-custom {
			color: rgba(0, 0, 0, .3);
			text-decoration: underline;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: 'Poppins', sans-serif;
			color: #000000;
			margin-top: 0;
			font-weight: 400;
		}

		body {
			font-family: 'Poppins', sans-serif;
			font-weight: 400;
			font-size: 15px;
			line-height: 1.8;
			color: rgba(0, 0, 0, .4);
		}

		a {
			color: #109518;
		}

		table {}

		/*LOGO*/

		.logo h1 {
			margin: 0;
		}

		.logo h1 a {
			color: #109518;
			font-size: 24px;
			font-weight: 700;
			font-family: 'Poppins', sans-serif;
		}

		/*HERO*/
		.hero {
			position: relative;
			z-index: 0;
		}

		.hero .text {
			color: rgba(0, 0, 0, .3);
		}

		.hero .text h2 {
			color: #000;
			font-size: 34px;
			margin-bottom: 0;
			font-weight: 200;
			line-height: 1.4;
		}

		.hero .text h3 {
			font-size: 24px;
			font-weight: 300;
		}

		.hero .text h2 span {
			font-weight: 600;
			color: #000;
		}

		.text-author {
			bordeR: 1px solid rgba(0, 0, 0, .05);
			max-width: 50%;
			margin: 0 auto;
			padding: 2em;
		}

		.text-author img {
			border-radius: 50%;
			padding-bottom: 20px;
		}

		.text-author h3 {
			margin-bottom: 0;
		}

		ul.social {
			padding: 0;
		}

		ul.social li {
			display: inline-block;
			margin-right: 10px;
		}

		/*FOOTER*/

		.mFooter {
			border-top: 1px solid rgba(0, 0, 0, .05);
			color: rgba(0, 0, 0, .5);
		}

		.mFooter .heading {
			color: #000;
			font-size: 20px;
		}

		.mFooter ul {
			margin: 0;
			padding: 0;
		}

		.mFooter ul li {
			list-style: none;
			margin-bottom: 10px;
		}

		.mFooter ul li a {
			color: rgba(0, 0, 0, 1);
		}


		@media screen and (max-width: 500px) {}
	</style>



</head>

<body width="100%" style="margin: 0; padding: 0 !important; mso-line-height-rule: exactly; background-color: #f1f1f1;">
	<center style="width: 100%; background-color: #f1f1f1;">
		<div style="display: none; font-size: 1px;max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
			&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
		</div>
		<div style="max-width: 600px; margin: 0 auto;" class="email-container">
			<!-- BEGIN BODY -->
			<table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
				<tr>
					<td valign="top" class="bg_white" style="padding: 1em 2.5em 0 2.5em;">
						<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
							<tr>
								<td class="logo" style="text-align: center;">
									<h1><a href="#">{GREATING}</a></h1>
								</td>
							</tr>
						</table>
					</td>
				</tr><!-- end tr -->
				<tr>
					<td valign="middle" class="hero bg_white" style="padding: 2em 0 4em 0;">
						<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
							<tr>
								<td style="padding: 0 2.5em; text-align: center; padding-bottom: 3em;">
									<div class="text">
										<h3>{EMAIL_BODY}</h3>
									</div>
								</td>
							</tr>
							<tr>
								<td style="text-align: center;">
									<p><a href="{PROFILE_LINK}" class="btn btn-primary">{BUTTON_LABEL}</a></p>
								</td>
							</tr>
						</table>
					</td>
				</tr><!-- end tr -->
				<!-- 1 Column Text + Button : END -->
			</table>
			<table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
				<tr>
					<td valign="middle" class="m_bg_light mFooter email-section">
						<table>
							<tr>
								<td valign="top" width="33.333%" style="padding-top: 20px;">
									<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
										<tr>
											<td style="text-align: left; padding-right: 10px;">
												<h3 class="heading">About</h3>
												<p>{COMPANY_ABOUT}</p>
											</td>
										</tr>
									</table>
								</td>
								<td valign="top" width="33.333%" style="padding-top: 20px;">
									<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
										<tr>
											<td style="text-align: left; padding-left: 5px; padding-right: 5px;">
												<h3 class="heading">Contact Info</h3>
												<ul>
													<li><span class="text">{COMPANY_ADDRESS}</span></li>
													<li><span class="text">{COMPANY_PHONE}</span></a></li>
												</ul>
											</td>
										</tr>
									</table>
								</td>
								<td valign="top" width="33.333%" style="padding-top: 20px;">
									<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
										<tr>
											<td style="text-align: left; padding-left: 10px;">
												<h3 class="heading">Useful Links</h3>
												<ul>
													<li><a href="#">Home</a></li>
													<li><a href="#">About</a></li>
													<li><a href="#">Services</a></li>
													<li><a href="#">Work</a></li>
												</ul>
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</td>
				</tr><!-- end: tr -->
				<tr>
					<td class="m_bg_light" style="text-align: center;">
						<p>No longer want to receive these email? You can <a href="{UNSUBSCRIBE_URL}" style="color: rgba(0,0,0,.8);">Unsubscribe</a></p>
					</td>
				</tr>
			</table>

		</div>
	</center>
</body>

</html>`
};

export const notificationsTemplate = {
	id: "NOTIFICATION_TEMPLATE",
	creator: "alex.wagaana@yellowbird.mobi",
	text: "Notifications Template",
	purpose: "notifications",
	html: `<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
	<link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700" rel="stylesheet">

	<style>
		/* What it does: Remove spaces around the email design added by some email clients. */
		/* Beware: It can remove the padding / margin and add a background color to the compose a reply window. */
		html,
		body {
			margin: 0 auto !important;
			padding: 0 !important;
			height: 100% !important;
			width: 100% !important;
			background: #f1f1f1;
		}

		/* What it does: Stops email clients resizing small text. */
		* {
			-ms-text-size-adjust: 100%;
			-webkit-text-size-adjust: 100%;
		}

		/* What it does: Centers email on Android 4.4 */
		div[style*="margin: 16px 0"] {
			margin: 0 !important;
		}

		/* What it does: Stops Outlook from adding extra spacing to tables. */
		table,
		td {
			mso-table-lspace: 0pt !important;
			mso-table-rspace: 0pt !important;
		}

		/* What it does: Fixes webkit padding issue. */
		table {
			border-spacing: 0 !important;
			border-collapse: collapse !important;
			table-layout: fixed !important;
			margin: 0 auto !important;
		}

		/* What it does: Uses a better rendering method when resizing images in IE. */
		img {
			-ms-interpolation-mode: bicubic;
		}

		/* What it does: Prevents Windows 10 Mail from underlining links despite inline CSS. Styles for underlined links should be inline. */
		a {
			text-decoration: none;
		}

		/* What it does: A work-around for email clients meddling in triggered links. */
		*[x-apple-data-detectors],
		/* iOS */
		.unstyle-auto-detected-links *,
		.aBn {
			border-bottom: 0 !important;
			cursor: default !important;
			color: inherit !important;
			text-decoration: none !important;
			font-size: inherit !important;
			font-family: inherit !important;
			font-weight: inherit !important;
			line-height: inherit !important;
		}

		/* What it does: Prevents Gmail from displaying a download button on large, non-linked images. */
		.a6S {
			display: none !important;
			opacity: 0.01 !important;
		}

		/* What it does: Prevents Gmail from changing the text color in conversation threads. */
		.im {
			color: inherit !important;
		}

		/* If the above doesn't work, add a .g-img class to any image in question. */
		img.g-img+div {
			display: none !important;
		}

		/* What it does: Removes right gutter in Gmail iOS app: https://github.com/TedGoas/Cerberus/issues/89  */
		/* Create one of these media queries for each additional viewport size you'd like to fix */

		/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
		@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
			u~div .email-container {
				min-width: 320px !important;
			}
		}

		/* iPhone 6, 6S, 7, 8, and X */
		@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
			u~div .email-container {
				min-width: 375px !important;
			}
		}

		/* iPhone 6+, 7+, and 8+ */
		@media only screen and (min-device-width: 414px) {
			u~div .email-container {
				min-width: 414px !important;
			}
		}

		.primary {
			background: #109518;
		}

		.bg_white {
			background: #ffffff;
		}

		.bg_light {
			background: #f7fafa;
		}

		.bg_black {
			background: #000000;
		}

		.bg_dark {
			background: rgba(0, 0, 0, .8);
		}

		.email-section {
			padding: 2.5em;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: 'Poppins', sans-serif;
			color: #000000;
			margin-top: 0;
			font-weight: 400;
		}

		body {
			font-family: 'Poppins', sans-serif;
			font-weight: 400;
			font-size: 15px;
			line-height: 1.8;
			color: rgba(0, 0, 0, .4);
		}

		a {
			color: #109518;
		}

		table {}

		/*LOGO*/

		.logo h1 {
			margin: 0;
		}

		.logo h1 a {
			color: #109518;
			font-size: 24px;
			font-weight: 700;
			font-family: 'Poppins', sans-serif;
		}

		/*HERO*/
		.hero {
			position: relative;
			z-index: 0;
		}

		.hero .text {
			color: rgba(0, 0, 0, .3);
		}

		.hero .text h2 {
			color: #000;
			font-size: 34px;
			margin-bottom: 0;
			font-weight: 200;
			line-height: 1.4;
		}

		.hero .text h3 {
			font-size: 24px;
			font-weight: 300;
		}

		.hero .text h2 span {
			font-weight: 600;
			color: #000;
		}

		.text-author {
			bordeR: 1px solid rgba(0, 0, 0, .05);
			max-width: 50%;
			margin: 0 auto;
			padding: 2em;
		}

		.text-author img {
			border-radius: 50%;
			padding-bottom: 10px;
		}

		.text-author h3 {
			margin-bottom: 0;
		}

		ul.social {
			padding: 0;
		}

		ul.social li {
			display: inline-block;
			margin-right: 10px;
		}

		@media screen and (max-width: 500px) {}
	</style>



</head>

<body width="100%" style="margin: 0; padding: 0 !important; mso-line-height-rule: exactly; background-color: #f1f1f1;">
	<center style="width: 100%; background-color: #f1f1f1;">
		<div style="display: none; font-size: 1px;max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
			&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
		</div>
		<div style="max-width: 600px; margin: 0 auto;" class="email-container">
			<!-- BEGIN BODY -->
			<table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
				<tr>
					<td valign="middle" class="hero bg_white" style="padding: 2em 0 4em 0;">
						<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
							<tr>
								<td style="padding: 0 2.5em; text-align: center; padding-bottom: 3em;">
									<div class="text">
										<h3>{MESSAGE_BODY}</h3>
                    <img id = "fileURL" alt = "fileURL" src = "{FileURL}" {STYLE} class = "text-author" />
									</div>
								</td>
							</tr>
						</table>
					</td>
				</tr><!-- end tr -->
				<!-- 1 Column Text + Button : END -->
			</table>

		</div>
	</center>
</body>

</html>`
}


export const source = [{
	label: "WHITE",
	code: "#FFFFFF"
},
{
	label: "SILVER",
	code: "#C0C0C0"
},
{
	label: "GRAY",
	code: "#808080"
},
{
	label: "BLACK",
	code: "#000000"
},
{
	label: "RED",
	code: "#FF0000"
},
{
	label: "MAROON",
	code: "#800000"
},
{
	label: "YELLOW",
	code: "#FFFF00"
},
{
	label: "OLIVE",
	code: "#808000"
},
{
	label: "LIME",
	code: "#00FF00"
},
{
	label: "GREEN",
	code: "#008000"
},
{
	label: "AQUA",
	code: "#00FFFF"
},
{
	label: "TEAL",
	code: "#008080"
},
{
	label: "BLUE",
	code: "#0000FF"
},
{
	label: "NAVY",
	code: "#000080"
},
{
	label: "FUCHSIA",
	code: "#FF00FF"
},
{
	label: "PURPLE",
	code: "#800080"
}
];

export const destination = [{
	label: "AQUA",
	code: "#00FFFF"
},
{
	label: "TEAL",
	code: "#008080"
},
{
	label: "BLUE",
	code: "#0000FF"
},
{
	label: "NAVY",
	code: "#000080"
},
{
	label: "FUCHSIA",
	code: "#FF00FF"
},
{
	label: "PURPLE",
	code: "#800080"
}
];