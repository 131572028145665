import Vue from "vue";
import VueRouter from "vue-router";
import AuthRequired from "./utils/AuthRequired";
import CompanyRequired from "./utils/CompanyRequired";

Vue.use( VueRouter );
const routes = [ {
        path: "/",
        redirect: "/home",
    },
    {
        path: "/application",
        component: () => import( "./views/app/dashboard/Application.vue" ),
        beforeEnter: AuthRequired,

        children: [ {
            path: "/home",
            component: () => import( "./views/app/dashboard/Home.vue" ),
            beforeEnter: ( AuthRequired && CompanyRequired ),
        }, {
            path: "/wallet",
            component: () => import( "./views/app/dashboard/Wallet.vue" ),
            beforeEnter: ( AuthRequired && CompanyRequired ),
        }, {
            path: "/credentials",
            component: () => import( "./views/app/dashboard/ApiCredentials.vue" ),
            beforeEnter: ( AuthRequired && CompanyRequired ),
        }, {
            path: "/requests",
            component: () => import( "./views/app/dashboard/Requests.vue" ),
            beforeEnter: ( AuthRequired && CompanyRequired ),
        }, {
            path: "/createNewRequest",
            component: () => import( "./views/app/dashboard/CreateNewRequest.vue" ),
            beforeEnter: ( AuthRequired && CompanyRequired ),
        }, {
            path: "/requestDetails",
            component: () => import( "./views/app/dashboard/RequestDetails.vue" ),
            beforeEnter: ( AuthRequired && CompanyRequired ),
        }, {
            path: "/settings",
            component: () => import( "./views/app/dashboard/Settings.vue" ),
            beforeEnter: AuthRequired,
            redirect: "/generalSettings",

            children: [ {
                path: "/profileSettings",
                component: () => import( "./views/app/settings/ProfileSettings.vue" ),
                beforeEnter: AuthRequired,
            }, {
                path: "/generalSettings",
                component: () => import( "./views/app/settings/GeneralSettings.vue" ),
                beforeEnter: ( AuthRequired && CompanyRequired ),
            }, {
                path: "/companySettings",
                component: () => import( "./views/app/settings/CompanySettings.vue" ),
                beforeEnter: ( AuthRequired && CompanyRequired ),
            }, {
                path: "/createCompany",
                component: () => import( "./views/app/settings/CreateCompany.vue" ),
                beforeEnter: ( AuthRequired && CompanyRequired ),
            }, {
                path: "/page",
                component: () => import( "./views/app/dashboard/Page.vue" ),
                beforeEnter: AuthRequired,
            }, {
                path: "/myUsers",
                component: () => import( "./views/app/settings/Users.vue" ),
                beforeEnter: ( AuthRequired && CompanyRequired ),
            } ],
        }, {
            path: "/apiDocumentation",
            component: () => import( "./views/app/documentation/ApiDocumentation.vue" ),
            beforeEnter: AuthRequired,
            redirect: "/directRequestDelivery",

            children: [ {
                path: "/directRequestDelivery",
                component: () => import( "./views/app/documentation/DirectRequestDelivery.vue" ),
                beforeEnter: AuthRequired,
            }, {
                path: "/estimatedDeliveryPrice",
                component: () => import( "./views/app/documentation/EstimatedDeliveryPrice.vue" ),
                beforeEnter: ( AuthRequired && CompanyRequired ),
            }, {
                path: "/nearestDriverDistance",
                component: () => import( "./views/app/documentation/NearestDriverDistance.vue" ),
                beforeEnter: ( AuthRequired && CompanyRequired ),
            }, {
                path: "/driverLocation",
                component: () => import( "./views/app/documentation/DriverLocation.vue" ),
                beforeEnter: ( AuthRequired && CompanyRequired ),
            }, {
                path: "/requestStatus",
                component: () => import( "./views/app/documentation/RequestStatus.vue" ),
                beforeEnter: ( AuthRequired && CompanyRequired ),
            },]
        }, {
            path: "/community",
            component: () => import( "./views/app/documentation/Community.vue" ),
            beforeEnter: AuthRequired,
        }, ],
    },
    {
        path: "/companies",
        component: () => import( "./views/app/Companies.vue" ),
        beforeEnter: AuthRequired,
    },
    {
        path: "/login",
        component: () => import( "./views/app/user/Login.vue" ),
    }, {
        path: "/forgotPassword",
        component: () => import( "./views/app/user/ForgotPassword.vue" ),
    }, {
        path: "/authHandler",
        component: () => import( "./views/app/user/AuthHandler.vue" ),
    },
    {
        path: "/requestStatus",
        component: () => import( "./views/app/api/RequestStatus.vue" ),
    },
    {
        path: "/register",
        component: () => import( "./views/app/user/Register.vue" ),
    },
    {
        path: "*",
        component: () => import( "./views/Error" ),
    },
];

const router = new VueRouter( {
    linkActiveClass: "active",
    routes,
    mode: "hash",
} );

export default router;