import {
    firebase
} from '@firebase/app';
import '@firebase/firestore'
import '@firebase/auth';
import '@firebase/database';
import '@firebase/storage';

var crypto = require( 'crypto' );
const databaseUtils = require( "../../utils/databaseUtils" );

const state = {
    freelancerCompany: {},
    myProfile: {},
    activeCompany: localStorage.getItem( 'activeCompany' ) != null ? JSON.parse( localStorage.getItem( 'activeCompany' ) ) : null,
    myCompanies: [],
    myCompanyUsers: []
}

const getters = {
    getFreelancerCompany: state => {
        return state.freelancerCompany;
    },
    getMyProfile: state => {
        return state.myProfile;
    },
    getMyCompanies: state => {
        return state.myCompanies;
    },
    getActiveCompany: state => {
        return state.activeCompany;
    },
    getMyCompanyUsers: state => {
        return state.myCompanyUsers;
    },
}

const mutations = {
    freelancerCompany( state, payload ) {
        state.freelancerCompany = payload;
    },

    addMyProfile( state, payload ) {
        state.myProfile = payload;
    },

    saveMyCompanies( state, payload ) {
        state.myCompanies = payload;
    },

    setActiveCompany( state, payload ) {
        localStorage.setItem( 'activeCompany', JSON.stringify( payload ) )
        state.activeCompany = payload;
    },

    addMyCompanyUsers( state, payload ) {
        state.myCompanyUsers = payload;
    },
}


const actions = {
    fetchMyCompanies( {
        commit
    } ) {
        console.log( 'Ready to fetch companies' );
        var currentUser = JSON.parse( localStorage.getItem( 'user' ) );
        firebase.firestore().collection( databaseUtils.COMPANY_USERS )
            .where( 'email', '==', currentUser.email ).get().then( ( querySnapshot ) => {
                let myCompanyUsers = [];
                querySnapshot.forEach( ( doc ) => {
                    myCompanyUsers.push( doc.data() )
                } );

                let companiesPromise = [];
                for ( const index in myCompanyUsers ) {
                    companiesPromise.push( new Promise( ( resolve, reject ) => {
                        const singleCompanyUser = myCompanyUsers[ index ];

                        firebase.firestore().collection( databaseUtils.COMPANIES )
                            .doc( singleCompanyUser.companyID ).onSnapshot( querySnapshot => {
                                var company = querySnapshot.data();
                                resolve( company );
                            } );
                    } ) );
                }

                Promise.all( companiesPromise )
                    .then( ( data ) => {
                        let myCompanies = [];
                        for ( let i = 0; i < data.length; i++ ) {
                            myCompanies.push( data[ i ] );
                        }
                        console.log( 'saveMyCompanies', myCompanies );
                        commit( 'saveMyCompanies', myCompanies );
                        return;
                    } )
                    .catch( ( error ) => {
                        console.log( error );
                        return;
                    } );
            } )
            .catch( ( error ) => {
                console.log( "Error getting documents: ", error );
            } );
    },

    submitActiveCompany( {
        commit,
        dispatch
    }, company ) {
        console.log( 'Ready to fetch' );
        commit( 'setActiveCompany', company );
        dispatch( "fireMessageAlert", {
            type: "success",
            title: "Success",
            body: 'Active Company successfully updated!'
        } );
    },

    updateCompanyApiStatus( {
        commit
    }, apiStatus ) {
        console.log( 'Ready to fetch' );
        var myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
        var washingtonRef = firebase.firestore().collection( databaseUtils.COMPANIES ).doc( myActiveCompany.id );

        // Set the "capital" field of the city 'DC'
        return washingtonRef.update( {
                apiStatus: apiStatus
            } )
            .then( () => {
                console.log( "Document successfully updated!" );
            } )
            .catch( ( error ) => {
                // The document probably doesn't exist.
                console.error( "Error updating document: ", error );
            } );
    },

    fetchMyCompany( {
        commit
    } ) {
        console.log( 'Ready to fetch active Company' );
        var myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
        firebase.firestore().collection( databaseUtils.COMPANIES )
            .doc( myActiveCompany.id ).onSnapshot( querySnapshot => {
                var freelancerCompany = querySnapshot.data();
                console.log( 'freelancerCompany', freelancerCompany );
                commit( 'freelancerCompany', freelancerCompany );
            } );
    },

    fetchMyCompanyUsers( {
        commit
    } ) {
        console.log( 'Ready to fetch My Company Users' );
        var myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
        firebase.firestore().collection( databaseUtils.COMPANY_USERS ).where( 'companyID', '==', myActiveCompany.id ).get().then( ( querySnapshot ) => {
            let myCompanyUsers = [];
            querySnapshot.forEach( ( doc ) => {
                myCompanyUsers.push( doc.data() )
            } );
            console.log( 'myCompanyUsers', myCompanyUsers );
            commit( 'addMyCompanyUsers', myCompanyUsers );
        } )
    },

    addNewCompany( {
        commit,
        dispatch
    }, data ) {
        console.log( 'Ready to fetch' )
        var user = JSON.parse( localStorage.getItem( 'user' ) );
        data[ "clientID" ] = user.userID;
        data[ "timeAdded" ] = new Date().getTime();
        data[ "id" ] = databaseUtils.filterPath( data.companyName + "-@-" + data.timeAdded );

        var current_date = ( new Date() ).valueOf().toString();
        var random = Math.random().toString();
        var publicKey = crypto.createHash( 'sha1' ).update( user.userID ).digest( 'hex' );
        var privateKey = crypto.createHash( 'sha1' ).update( user.userID + current_date + random ).digest( 'hex' );

        data[ "publicKey" ] = publicKey;
        data[ "privateKey" ] = privateKey;
        console.log( user );

        firebase.firestore().collection( databaseUtils.COMPANIES )
            .doc( data.id ).set( data ).then( ref => {
                console.log( 'Added COMPANY with ID: ', data.id );

                const currentTimeMilis = new Date().getTime();
                user.companyID = data.id;
                user.dateAdded = currentTimeMilis;
                user.lastSeen = currentTimeMilis;
                user.status = "owner";

                user.id = user.userID + "-@-" + data.id;

                firebase.firestore().collection( databaseUtils.COMPANY_USERS )
                    .doc( user.id ).set( user ).then( ref => {
                        console.log( 'Added COMPANY USER with ID: ', data.id );
                        dispatch( "fireMessageAlert", {
                            type: "success",
                            title: "Success",
                            body: 'Added COMPANY'
                        } );
                    } );
            } );
    },

    resetMyCompanyApiKeys( {
        commit,
        dispatch
    } ) {
        var user = JSON.parse( localStorage.getItem( 'user' ) );

        var current_date = ( new Date() ).valueOf().toString();
        var random = Math.random().toString();
        var publicKey = crypto.createHash( 'sha1' ).update( user.userID + random ).digest( 'hex' );
        var privateKey = crypto.createHash( 'sha1' ).update( user.userID + current_date + random ).digest( 'hex' );

        // alert(state.freelancerCompany.id)
        let addDoc = firebase.firestore().collection( databaseUtils.COMPANIES ).doc( state.freelancerCompany.id )
            .update( {
                publicKey: publicKey,
                privateKey: privateKey
            } )
            .then( function () {
                console.log( "Document successfully updated!" );
                dispatch( "fireMessageAlert", {
                    type: "success",
                    title: "Success",
                    body: 'API Keys successfully reset'
                } );
            } )
            .catch( function ( error ) {
                // The document probably doesn't exist.
                console.error( "Error updating document: ", error );
                dispatch( "fireMessageAlert", {
                    type: "success",
                    title: "Success",
                    body: 'Error reseting keys'
                } );
            } );
    },

    fetchMyProfile( {
        commit
    }, clientID ) {
        var currentUser = JSON.parse( localStorage.getItem( 'user' ) );
        if ( clientID !== undefined ) {
            firebase.firestore().collection( databaseUtils.PROFILES )
                .doc( clientID ).onSnapshot( querySnapshot => {
                    var myProfile = querySnapshot.data();
                    console.log( myProfile );
                    commit( 'addMyProfile', myProfile !== undefined ? myProfile : {} );
                } );
        } else {
            firebase.firestore().collection( databaseUtils.PROFILES )
                .doc( currentUser.userID ).onSnapshot( querySnapshot => {
                    var myProfile = querySnapshot.data();
                    console.log( myProfile );
                    commit( 'addMyProfile', myProfile !== undefined ? myProfile : {} );
                } );
        }
    },

    updateMyProfile( {
        commit,
        dispatch
    }, payload ) {
        var currentUser = JSON.parse( localStorage.getItem( 'user' ) );
        firebase.firestore().collection( databaseUtils.PROFILES ).doc( currentUser.userID )
            .update( payload ).then( function () {
                console.log( "Document successfully updated!" );
                dispatch( "fireMessageAlert", {
                    type: "success",
                    title: "Success",
                    body: 'Profile updated successfully'
                } );
            } )
            .catch( function ( error ) {
                console.error( "Error updating document: ", error );
                dispatch( "fireMessageAlert", {
                    type: "success",
                    title: "Success",
                    body: 'Error updating profile: ' + error
                } );
            } );
    },

    createNewCompanyUserProfile( {
        commit,
        dispatch
    }, payload ) {
        var currentUser = JSON.parse( localStorage.getItem( 'user' ) );
        var myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
        let userProfile = payload.user;

        var metadata = {
            contentType: "image/jpeg",
        };
        // Create a root reference
        var storageRef = firebase.storage().ref();

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef
            .child( "webimages/api/accounts/" + payload.file.name )
            .put( payload.file, metadata );

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function ( snapshot ) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress =
                    ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100;
                console.log( "Upload is " + progress + "% done" );
                switch ( snapshot.state ) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log( "Upload is paused" );
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log( "Upload is running" );
                        break;
                }
            },
            function ( error ) {
                switch ( error.code ) {
                    case "storage/unauthorized":
                        // User doesn't have permission to access the object
                        break;

                    case "storage/canceled":
                        // User canceled the upload
                        break;

                    case "storage/unknown":
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            function () {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then( function ( downloadURL ) {
                    console.log( "File available at", downloadURL );

                    const currentTimeMilis = new Date().getTime();
                    userProfile.creatorID = currentUser.userID;
                    userProfile.imageURL = downloadURL;
                    userProfile.status = "manager";
                    userProfile.companyID = myActiveCompany.id;
                    userProfile.dateAdded = currentTimeMilis;
                    userProfile.lastSeen = currentTimeMilis;
                    userProfile.id = userProfile.email + "-@-" + myActiveCompany.id;

                    firebase.firestore().collection( databaseUtils.COMPANY_USERS ).doc( userProfile.id )
                        .set( userProfile ).then( function () {
                            console.log( "Document successfully updated!" );
                            dispatch( "fireMessageAlert", {
                                type: "success",
                                title: "Success",
                                body: 'Company user successfully created'
                            } );
                        } )
                        .catch( function ( error ) {
                            console.error( "Error updating document: ", error );
                            dispatch( "fireMessageAlert", {
                                type: "success",
                                title: "Success",
                                body: 'Error creating Company user' + error
                            } );
                        } );
                } );
            }
        );
    },

    updateCompanyProfile( {
        commit,
        dispatch
    }, company ) {
        console.log( 'Ready to fetch' );
        var myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
        var washingtonRef = firebase.firestore().collection( databaseUtils.COMPANIES ).doc( myActiveCompany.id );

        // Set the "capital" field of the city 'DC'
        return washingtonRef.update( company )
            .then( () => {
                console.log( "Document successfully updated!" );
                dispatch( "fireMessageAlert", {
                    type: "success",
                    title: "Success",
                    body: 'Company updated successfully!'
                } );
            } )
            .catch( ( error ) => {
                // The document probably doesn't exist.
                console.error( "Error updating document: ", error );
                dispatch( "fireMessageAlert", {
                    type: "success",
                    title: "Success",
                    body: "Error updating company: " + error
                } );
            } );
    },

    UploadCompanyFileToFirebase( {
        commit,
        dispatch
    }, payload ) {
        var metadata = {
            contentType: "image/jpeg",
        };
        // Create a root reference
        var storageRef = firebase.storage().ref();

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef
            .child( "marketing/notifications/" + payload.file.name + "-" + new Date().getTime() )
            .put( payload.file, metadata );

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function ( snapshot ) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress =
                    ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100;
                console.log( "Upload is " + progress + "% done" );
                switch ( snapshot.state ) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log( "Upload is paused" );
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log( "Upload is running" );
                        break;
                }
            },
            function ( error ) {
                switch ( error.code ) {
                    case "storage/unauthorized":
                        // User doesn't have permission to access the object
                        break;

                    case "storage/canceled":
                        // User canceled the upload
                        break;

                    case "storage/unknown":
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            function () {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then( function ( downloadURL ) {
                    console.log( "downloadURL", downloadURL );

                    var myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
                    var washingtonRef = firebase.firestore().collection( databaseUtils.COMPANIES )
                        .doc( myActiveCompany.id );

                    // Set the "capital" field of the city 'DC'
                    if ( payload.fileName === "CertificateOfIncoperation" ) {
                        return washingtonRef.update( {
                                CertificateOfIncoperation: downloadURL
                            } ).then( () => {
                                console.log( "Document successfully updated!" );
                                dispatch( "fireMessageAlert", {
                                    type: "success",
                                    title: "Success",
                                    body: 'Company updated successfully!'
                                } );
                            } )
                            .catch( ( error ) => {
                                // The document probably doesn't exist.
                                console.error( "Error updating document: ", error );
                                dispatch( "fireMessageAlert", {
                                    type: "success",
                                    title: "Success",
                                    body: "Error updating company: " + error
                                } );
                            } );
                    }
                    if ( payload.fileName === "MemorandamOfAsociation" ) {
                        return washingtonRef.update( {
                                MemorandamOfAsociation: downloadURL
                            } ).then( () => {
                                console.log( "Document successfully updated!" );
                                dispatch( "fireMessageAlert", {
                                    type: "success",
                                    title: "Success",
                                    body: 'Company updated successfully!'
                                } );
                            } )
                            .catch( ( error ) => {
                                // The document probably doesn't exist.
                                console.error( "Error updating document: ", error );
                                dispatch( "fireMessageAlert", {
                                    type: "success",
                                    title: "Success",
                                    body: "Error updating company: " + error
                                } );
                            } );
                    }
                    if ( payload.fileName === "CampanyDirectors" ) {
                        return washingtonRef.update( {
                                CampanyDirectors: downloadURL
                            } ).then( () => {
                                console.log( "Document successfully updated!" );
                                dispatch( "fireMessageAlert", {
                                    type: "success",
                                    title: "Success",
                                    body: 'Company updated successfully!'
                                } );
                            } )
                            .catch( ( error ) => {
                                // The document probably doesn't exist.
                                console.error( "Error updating document: ", error );
                                dispatch( "fireMessageAlert", {
                                    type: "success",
                                    title: "Success",
                                    body: "Error updating company: " + error
                                } );
                            } );
                    }
                    if ( payload.fileName === "ApplicantNationalID" ) {
                        return washingtonRef.update( {
                                ApplicantNationalID: downloadURL
                            } ).then( () => {
                                console.log( "Document successfully updated!" );
                                dispatch( "fireMessageAlert", {
                                    type: "success",
                                    title: "Success",
                                    body: 'Company updated successfully!'
                                } );
                            } )
                            .catch( ( error ) => {
                                // The document probably doesn't exist.
                                console.error( "Error updating document: ", error );
                                dispatch( "fireMessageAlert", {
                                    type: "success",
                                    title: "Success",
                                    body: "Error updating company: " + error
                                } );
                            } );
                    }
                } );
            }
        );
    },

    saveCallbackURL( {
        commit,
        dispatch
    }, payload ) {
        // alert(state.freelancerCompany.id)
        let addDoc = firebase.firestore().collection( databaseUtils.COMPANIES ).doc( state.freelancerCompany.id )
            .update( {
                callbackURL: payload,
            } )
            .then( function () {
                console.log( "Document successfully updated!" );
                dispatch( "fireMessageAlert", {
                    type: "success",
                    title: "Success",
                    body: 'Callback URL successfully updated'
                } );
            } )
            .catch( function ( error ) {
                // The document probably doesn't exist.
                console.error( "Error updating document: ", error );
                dispatch( "fireMessageAlert", {
                    type: "success",
                    title: "Success",
                    body: 'Error updating Callback URL'
                } );
            } );
    }
}
export default {
    state,
    getters,
    mutations,
    actions,
}