import {
  firebase
} from '@firebase/app';
import '@firebase/firestore'
import '@firebase/auth';
import '@firebase/database';
import '@firebase/storage';

var crypto = require( 'crypto' );
const databaseUtils = require( "../../utils/databaseUtils" );

const state = {
  companyReports: {}
}

const getters = {
  getCompanyReports: state => {
    return state.companyReports;
  },
}

const mutations = {
  setCompanyReports( state, payload ) {
    state.companyReports = payload;
  },
}


const actions = {
  fetchCompanyReports( {
    commit
  } ) {
    console.log( 'Ready to fetch active Company transactions' );
    var myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
    firebase.firestore().collection( databaseUtils.REPORTING ).doc( myActiveCompany.id )
      .get().then( snapshot => {
        if ( snapshot.exists ) {
          console.log( 'setCompanyReports', snapshot.data() );
          commit( 'setCompanyReports', snapshot.data() );
        }
      } );
  },
}
export default {
  state,
  getters,
  mutations,
  actions,
}