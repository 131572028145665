import {
  firebase
} from '@firebase/app';
import '@firebase/firestore'
import '@firebase/auth';
import '@firebase/database';
import '@firebase/storage';

var crypto = require( 'crypto' );
import axios from 'axios'
const databaseUtils = require( "../../utils/databaseUtils" );
const {
  deliveryData
} = require( "../../constants/data" );

const state = {
  companyRequests: [],
  vehicleTypes: [],
  singleRequest: {},
  estimatedDeliveryFee: {},
}

const getters = {
  getCompanyRequests: state => {
    return state.companyRequests;
  },
  getSingleRequest: state => {
    return state.singleRequest;
  },
  getVehicleTypes: state => {
    return state.vehicleTypes;
  },
  getEstimatedDeliveryFee: state => {
    return state.estimatedDeliveryFee;
  },
}

const mutations = {
  companyRequests( state, payload ) {
    state.companyRequests = payload;
  },

  addSingleRequest( state, payload ) {
    state.singleRequest = payload;
  },

  addVehicleTypes( state, payload ) {
    state.vehicleTypes = payload;
  },

  addEstimatedDeliveryFee( state, payload ) {
    state.estimatedDeliveryFee = payload;
  },
}


const actions = {
  fetchMyCompanyRequests( {
    commit
  }, requestType ) {
    console.log( 'Ready to fetch active Company transactions' );
    var myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
    let requestsRef = firebase.firestore().collection( databaseUtils.REQUESTS )
      .where( 'companyID', '==', myActiveCompany.id );

    if ( requestType === "pending" ) {
      requestsRef = requestsRef.where( 'status', '==', 'PENDING' );
    }
    requestsRef.get().then( ( querySnapshot ) => {

      let companyRequests = [];
      querySnapshot.forEach( ( doc ) => {
        companyRequests.push( doc.data() )
      } );

      console.log( 'companyRequests', companyRequests );
      commit( 'companyRequests', companyRequests );
    } );
  },

  requestDeliveryGuy( {
    commit,
    dispatch
  }, data ) {
    var myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
    var user = JSON.parse( localStorage.getItem( 'user' ) );

    firebase.firestore().collection( databaseUtils.COMPANIES )
      .doc( myActiveCompany.id ).onSnapshot( querySnapshot => {
        var freelancerCompany = querySnapshot.data();

        data.destination = `[${ data.destinationLocation.latitude }, ${ data.destinationLocation.longitude }]`;
        data.departure = `[${ data.pickupLocation.latitude }, ${ data.pickupLocation.longitude }]`;
        data.email = user.email;
        data.publicKey = freelancerCompany.publicKey;
        data.privateKey = freelancerCompany.privateKey;
        data.action = 'directRequestDelivery';
        console.log( data );

        axios.post( databaseUtils.apiURL, data )
          .then( response => {
            dispatch( "fireMessageAlert", {
              type: "success",
              title: "Success",
              body: response.data.message
            } );
          } );
      } );
  },

  fetchSingleRequest( {
    commit
  }, requestID ) {
    console.log( 'Ready to fetch User ORDER' );
    firebase.firestore().collection( databaseUtils.REQUESTS )
      .doc( requestID ).get().then( ( querySnapshot ) => {
        let singleRequest = querySnapshot.data();


        var myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
        firebase.firestore().collection( databaseUtils.COMPANIES )
          .doc( myActiveCompany.id ).onSnapshot( querySnapshot => {
            var freelancerCompany = querySnapshot.data();

            firebase.firestore().collection( databaseUtils.COMPANY_USERS )
              .where( 'companyID', '==', myActiveCompany.id )
              .where( 'email', '==', singleRequest.email )
              .get().then( ( snapshot ) => {

                let companyUserProfiles = [];
                snapshot.forEach( ( doc ) => {
                  companyUserProfiles.push( doc.data() )
                } );

                if ( companyUserProfiles.length > 0 ) {
                  singleRequest.author = companyUserProfiles[ 0 ];
                }

                singleRequest.positions = [];

                let departurePosition = {};
                departurePosition.lat = freelancerCompany.location.latitude;
                departurePosition.lng = freelancerCompany.location.longitude;
                departurePosition.key = "departurePosition";
                singleRequest.positions.push( departurePosition );

                const destination = JSON.parse( singleRequest.destination );
                let destinationPosition = {};
                destinationPosition.lat = destination[ 0 ];
                destinationPosition.lng = destination[ 1 ];
                destinationPosition.key = "destinationPosition";
                singleRequest.positions.push( destinationPosition );

                console.log( 'addSingleRequest', singleRequest );
                commit( 'addSingleRequest', singleRequest );
              } )
          } );


      } )
  },

  fetchVehicleTypes( {
    commit,
    dispatch
  }, payload ) {
    firebase.firestore().collection( databaseUtils.VEHICLE_TYPES )
      .where( 'available', '==', true )
      .get().then( ( snapshot ) => {
        let vehicleTypes = [];
        snapshot.forEach( ( doc ) => {
          vehicleTypes.push( doc.data() )
        } );
        commit( 'addVehicleTypes', vehicleTypes );
      } )
  },

  addDeliveryData( {
    commit,
    dispatch
  }, payload ) {
    for ( const m_index in deliveryData ) {
      firebase.firestore().collection( databaseUtils.VEHICLE_TYPES ).doc( deliveryData[ m_index ].id )
        .set( deliveryData[ m_index ] ).then( function () {
          console.log( deliveryData[ m_index ].id, "Document successfully updated!" );
        } )
        .catch( function ( error ) {
          console.error( deliveryData[ m_index ].id, "Error updating document: ", error );
        } );
    }
  },

  requestEstimatedDeliveryFee( {
    commit,
    dispatch
  }, data ) {
    var myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
    var user = JSON.parse( localStorage.getItem( 'user' ) );

    firebase.firestore().collection( databaseUtils.COMPANIES )
      .doc( myActiveCompany.id ).onSnapshot( querySnapshot => {
        var freelancerCompany = querySnapshot.data();

        data.destination = `[${ data.destinationLocation.latitude }, ${ data.destinationLocation.longitude }]`;
        data.departure = `[${ data.pickupLocation.latitude }, ${ data.pickupLocation.longitude }]`;
        data.email = user.email;
        data.publicKey = freelancerCompany.publicKey;
        data.privateKey = freelancerCompany.privateKey;
        data.action = 'nearestDriverDistance';
        console.log( data );

        axios.post( databaseUtils.apiURL, data )
          .then( response => {
            console.log( "response", response.data );
            commit( 'addEstimatedDeliveryFee', response.data );
          } );
      } );
  },
}
export default {
  state,
  getters,
  mutations,
  actions,
}