export const deliveryData = [ {
  "avgKmPerHourSpeed": 25,
  "capacity": 4,
  "category": "HAILING",
  "countryCode": "DEFAULT",
  "farePerKm": 0.5,
  "farePerMin": 0.1,
  "iconUrl": "https://www.uber-assets.com/image/upload/f_auto,q_auto:eco/v1554506931/navigation/UberXL.png",
  "id": "CAB_STANDARD",
  "label": "Cab standard",
  "maximumFare": 200,
  "minimumFare": 2,
  "perMinWaitingTime": 0.3,
  "popularity": 1,
  "roundUp": 1,
  "vehicleType": "CAB_STANDARD"
}, {
  "available": true,
  "avgKmPerHourSpeed": 0,
  "baseFare": 0.3,
  "capacity": 1,
  "category": "DELIVERY",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": "Tuck Tuck",
  "estimatedPrice": 0,
  "farePerKm": 0.3,
  "farePerMin": 0.1,
  "flatbed": false,
  "hitConservation": false,
  "iconUrl": "https://firebasestorage.googleapis.com/v0/b/yellowbird-967b2.appspot.com/o/app%2Fimages%2Ficons%2Ftricycle_motor.png?alt=media&token=dcabb8c6-d8ad-4e0e-bcd9-d5617063ac80",
  "itemViewHeight": 0,
  "label": "Tricycle",
  "maxWaitingTime": 0,
  "maximumFare": 1,
  "minimumFare": 0.2601,
  "minimumPrice": 0,
  "perMinWaitingTime": 0.005,
  "percentageFareAddition": 0,
  "popularity": 0,
  "refrigeration": false,
  "roundUp": 1,
  "settlementDuration": 30,
  "vehicleType": "DELIVERY_BIKE",
  "weightClass": "LIGHT_DELIVERY",
  "yellowBirdPercentage": 15
}, {
  "available": true,
  "avgKmPerHourSpeed": 0,
  "baseFare": 80,
  "capacity": 40,
  "category": "DELIVERY",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": "Standart truck",
  "estimatedPrice": 0,
  "farePerKm": 30000,
  "farePerMin": 500,
  "flatbed": true,
  "hitConservation": false,
  "iconUrl": "https://firebasestorage.googleapis.com/v0/b/yellowbird-967b2.appspot.com/o/app%2Fimages%2Ficons%2Ftruck_20_28_ton.png?alt=media&token=387cdb5a-8cc0-4a3e-81f7-896d99369cdd",
  "id": "DELIVERY_10_20_TON_TRUCK",
  "itemViewHeight": 0,
  "label": "20-28 Ton Truck",
  "maxWaitingTime": 0,
  "maximumFare": 200000,
  "minimumFare": 100,
  "minimumPrice": 0,
  "perMinWaitingTime": 0.019000000000000003,
  "percentageFareAddition": 0,
  "popularity": 0,
  "refrigeration": true,
  "roundUp": 1,
  "settlementDuration": 1200,
  "vehicleType": "DELIVERY_TRUCK",
  "weightClass": "HEAVY_DELIVERY",
  "yellowBirdPercentage": 15
}, {
  "available": true,
  "avgKmPerHourSpeed": 0,
  "baseFare": 20,
  "capacity": 6,
  "category": "DELIVERY",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": "Standart truck",
  "estimatedPrice": 0,
  "farePerKm": 5000,
  "farePerMin": 100,
  "flatbed": true,
  "hitConservation": false,
  "iconUrl": "https://firebasestorage.googleapis.com/v0/b/yellowbird-967b2.appspot.com/o/app%2Fimages%2Ficons%2Ftruck_3_5_ton.png?alt=media&token=22a51377-739d-4b40-9eed-e158ec1ed067",
  "id": "DELIVERY_3_TON_TRUCK",
  "itemViewHeight": 0,
  "label": "3-5 Ton Truck",
  "maxWaitingTime": 0,
  "maximumFare": 100000,
  "minimumFare": 50,
  "minimumPrice": 0,
  "perMinWaitingTime": 0.019000000000000003,
  "percentageFareAddition": 0,
  "popularity": 0,
  "refrigeration": true,
  "roundUp": 1,
  "settlementDuration": 60,
  "vehicleType": "DELIVERY_TRUCK",
  "weightClass": "MEDIUM_DELIVERY",
  "yellowBirdPercentage": 15
}, {
  "available": true,
  "avgKmPerHourSpeed": 0,
  "baseFare": 20,
  "capacity": 6,
  "category": "DELIVERY",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": "Heavy truck",
  "estimatedPrice": 0,
  "farePerKm": 10000,
  "farePerMin": 200,
  "flatbed": true,
  "hitConservation": false,
  "iconUrl": "https://firebasestorage.googleapis.com/v0/b/yellowbird-967b2.appspot.com/o/app%2Fimages%2Ficons%2Ftruck_5_10_ton.png?alt=media&token=e1178c25-0755-43ed-ac54-76b07dc9a905",
  "id": "DELIVERY_5_10_TON_TRUCK",
  "itemViewHeight": 0,
  "label": "5-10 Ton Truck",
  "maxWaitingTime": 0,
  "maximumFare": 100000,
  "minimumFare": 50,
  "minimumPrice": 0,
  "perMinWaitingTime": 0.019000000000000003,
  "percentageFareAddition": 0,
  "popularity": 0,
  "refrigeration": true,
  "roundUp": 1,
  "settlementDuration": 60,
  "vehicleType": "DELIVERY_TRUCK",
  "weightClass": "HEAVY_DELIVERY",
  "yellowBirdPercentage": 15
}, {
  "available": true,
  "avgKmPerHourSpeed": 0,
  "baseFare": 0.3,
  "capacity": 1,
  "category": "DELIVERY",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": "Normal bike with no box",
  "estimatedPrice": 0,
  "farePerKm": 500,
  "farePerMin": 100,
  "flatbed": false,
  "hitConservation": false,
  "iconUrl": "https://firebasestorage.googleapis.com/v0/b/yellowbird-967b2.appspot.com/o/app%2Fimages%2Ficons%2Fbike_no_box.png?alt=media&token=6e7d5dce-bc41-4fbf-b1a3-de3be1bfdc11",
  "id": "DELIVERY_BIKE",
  "itemViewHeight": 0,
  "label": "Bike",
  "maxWaitingTime": 0,
  "maximumFare": 1,
  "minimumFare": 0.2601,
  "minimumPrice": 0,
  "perMinWaitingTime": 0.005,
  "percentageFareAddition": 0,
  "popularity": 0,
  "refrigeration": false,
  "roundUp": 1,
  "settlementDuration": 30,
  "vehicleType": "DELIVERY_BIKE",
  "weightClass": "LIGHT_DELIVERY",
  "yellowBirdPercentage": 15
}, {
  "available": true,
  "avgKmPerHourSpeed": 0,
  "baseFare": 0.3,
  "capacity": 1,
  "category": "DELIVERY",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": "Boxed bike, water proof, hit conservation",
  "estimatedPrice": 0,
  "farePerKm": 0.16,
  "farePerMin": 0.1,
  "flatbed": false,
  "hitConservation": true,
  "iconUrl": "https://firebasestorage.googleapis.com/v0/b/yellowbird-967b2.appspot.com/o/app%2Fimages%2Ficons%2FdeliveryBike.png?alt=media&token=fd9ef1fd-e878-4462-b3d3-aa90ce3de1c1",
  "id": "DELIVERY_BIKE_BOX",
  "itemViewHeight": 0,
  "label": "Bike with box",
  "maxWaitingTime": 0,
  "maximumFare": 1,
  "minimumFare": 0.2601,
  "minimumPrice": 0,
  "perMinWaitingTime": 0.005,
  "percentageFareAddition": 0,
  "popularity": 0,
  "refrigeration": false,
  "roundUp": 1,
  "settlementDuration": 30,
  "vehicleType": "DELIVERY_BIKE_BOX",
  "weightClass": "LIGHT_DELIVERY",
  "yellowBirdPercentage": 15
}, {
  "available": true,
  "avgKmPerHourSpeed": 0,
  "baseFare": 10,
  "capacity": 6,
  "category": "DELIVERY",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": "Standard cab, small van",
  "estimatedPrice": 0,
  "farePerKm": 0.7,
  "farePerMin": 0.1,
  "flatbed": false,
  "hitConservation": false,
  "iconUrl": "https://firebasestorage.googleapis.com/v0/b/yellowbird-967b2.appspot.com/o/app%2Fimages%2Ficons%2Fcab_delivery.png?alt=media&token=a79901e2-087d-4177-a1ce-e913687d570d",
  "id": "DELIVERY_CAB",
  "itemViewHeight": 0,
  "label": "Cab, Small van",
  "maxWaitingTime": 0,
  "maximumFare": 1000,
  "minimumFare": 10,
  "minimumPrice": 0,
  "perMinWaitingTime": 0.2,
  "percentageFareAddition": 0,
  "popularity": 0,
  "refrigeration": false,
  "roundUp": 1,
  "settlementDuration": 90,
  "vehicleType": "DELIVERY_CAB",
  "weightClass": "MEDIUM_DELIVERY",
  "yellowBirdPercentage": 15
}, {
  "available": true,
  "avgKmPerHourSpeed": 0,
  "baseFare": 15,
  "capacity": 6,
  "category": "DELIVERY",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": "closed truck",
  "estimatedPrice": 0,
  "farePerKm": 3,
  "farePerMin": 0.3,
  "flatbed": true,
  "hitConservation": false,
  "iconUrl": "https://firebasestorage.googleapis.com/v0/b/yellowbird-967b2.appspot.com/o/app%2Fimages%2Ficons%2Fpickup_truck_closed.png?alt=media&token=74f55c7b-2726-49cb-972e-e1e09df553f7",
  "id": "DELIVERY_PICKUP_TRUCK",
  "itemViewHeight": 0,
  "label": "Closed Pickup",
  "maxWaitingTime": 0,
  "maximumFare": 1000,
  "minimumFare": 10,
  "minimumPrice": 0,
  "perMinWaitingTime": 0.2,
  "percentageFareAddition": 0,
  "popularity": 0,
  "refrigeration": false,
  "roundUp": 1,
  "settlementDuration": 90,
  "vehicleType": "DELIVERY_PICKUP_TRUCK",
  "weightClass": "MEDIUM_DELIVERY",
  "yellowBirdPercentage": 15
}, {
  "available": true,
  "avgKmPerHourSpeed": 0,
  "baseFare": 10,
  "capacity": 6,
  "category": "DELIVERY",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": "Opened Truck",
  "estimatedPrice": 0,
  "farePerKm": 2,
  "farePerMin": 0.3,
  "flatbed": false,
  "hitConservation": false,
  "iconUrl": "https://firebasestorage.googleapis.com/v0/b/yellowbird-967b2.appspot.com/o/app%2Fimages%2Ficons%2Fpickup_truck_opened.png?alt=media&token=21e7be26-2f21-429d-8009-1be20da0d06f",
  "id": "DELIVERY_PICKUP_TRUCK_OPENED",
  "itemViewHeight": 0,
  "label": "Pickup",
  "maxWaitingTime": 0,
  "maximumFare": 1000,
  "minimumFare": 10,
  "minimumPrice": 0,
  "perMinWaitingTime": 0.2,
  "percentageFareAddition": 0,
  "popularity": 0,
  "refrigeration": false,
  "roundUp": 1,
  "settlementDuration": 90,
  "vehicleType": "DELIVERY_PICKUP_TRUCK",
  "weightClass": "MEDIUM_DELIVERY",
  "yellowBirdPercentage": 15
}, {
  "available": true,
  "avgKmPerHourSpeed": 60,
  "baseFare": 10000,
  "capacity": 8,
  "category": "HAILING",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": null,
  "estimatedDistance": null,
  "estimatedDuration": null,
  "estimatedPrice": null,
  "farePerKg": null,
  "farePerKm": 3000,
  "farePerMin": 500,
  "flatbed": null,
  "hitConservation": null,
  "iconUrl": "https://guidetoiceland.is/image/279039/x/0/1-is.jpg",
  "id": "UG-SUV",
  "itemViewHeight": null,
  "label": "SUV",
  "maxWaitingTime": null,
  "maximumFare": 25000,
  "minimumFare": null,
  "perMinWaitingTime": null,
  "percentageFareAddition": null,
  "popularity": 20,
  "refrigeration": null,
  "roundUp": 100,
  "settlementDuration": null,
  "status": null,
  "vehicleType": "SUV",
  "weightClass": "CAR",
  "yellowBirdPercentage": null
}, {
  "avgKmPerHourSpeed": 25,
  "baseFare": 2000,
  "capacity": 4,
  "category": "HAILING",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": "Safe and Convenient",
  "farePerKm": 1500,
  "farePerMin": 160,
  "iconUrl": "https://www.uber-assets.com/image/upload/f_auto,q_auto:eco/v1554506931/navigation/UberXL.png",
  "id": "CAB_STANDARD",
  "label": "Cab standard",
  "maxWaitingTime": 420,
  "minimumFare": 7000,
  "popularity": 5,
  "roundUp": 500,
  "vehicleType": "CAB_STANDARD",
  "yellowBirdPercentage": 15
}, {
  "available": true,
  "avgKmPerHourSpeed": 45,
  "baseFare": 1000,
  "capacity": 1,
  "category": "HEAVY_DELIVERY",
  "countryCode": "UG",
  "currency": "UGX",
  "description": "Safe and Convenient",
  "farePerKm": 100,
  "farePerMin": 10,
  "iconUrl": "https://firebasestorage.googleapis.com/v0/b/yellowbird-967b2.appspot.com/o/app%2Fimages%2Ficons%2Ftruck_10_20_ton.png?alt=media&token=abc2c2e5-6049-48d1-bbb8-e76c6c79d4e5",
  "id": "DELIVERY_TRUCK",
  "label": "Standart truck",
  "maximumFare": 100000,
  "minimumFare": 1,
  "popularity": 1,
  "roundUp": 1,
  "vehicleType": "DELIVERY_TRUCK"
}, {
  "avgKmPerHourSpeed": 26,
  "baseFare": 500,
  "capacity": 1,
  "category": "HAILING",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": "To beat the traffic",
  "farePerKm": 400,
  "farePerMin": 50,
  "iconUrl": "https://firebasestorage.googleapis.com/v0/b/yellowbird-967b2.appspot.com/o/app%2Fimages%2Fvehicle_types%2Fboda.png?alt=media&token=6a526ea4-bf4c-4fb2-a2c9-591e7ef9490a",
  "id": "MOTOR_BIKE",
  "label": "Boda Boda",
  "maxWaitingTime": 420,
  "minimumFare": 1500,
  "popularity": 3,
  "roundUp": 500,
  "vehicleType": "MOTOR_BIKE",
  "yellowBirdPercentage": 15
}, {
  "avgKmPerHourSpeed": 20,
  "baseFare": 1000,
  "category": "HAILING",
  "countryCode": "UG",
  "currency": "UGX",
  "currencySymbol": "UGX",
  "description": "New in town",
  "farePerKm": 900,
  "farePerMin": 70,
  "iconUrl": "https://firebasestorage.googleapis.com/v0/b/yellowbird-967b2.appspot.com/o/app%2Fimages%2Fvehicle_types%2Ftuk_tuk_bw.png?alt=media&token=f8b2e98c-53f9-413e-b751-00d3e88246f8",
  "id": "TUK_TUK",
  "label": "Tricycles",
  "maxWaitingTime": 420,
  "minimumFare": 3000,
  "minimumPrice": 3000,
  "popularity": 1,
  "roundUp": 500,
  "vehicleType": "TRICYCLE",
  "yellowBirdPercentage": 15
} ]