import {
  firebase
} from '@firebase/app';
import '@firebase/firestore'
import '@firebase/auth';
import '@firebase/database';
import '@firebase/storage';

const crypto = require( 'crypto' );
const databaseUtils = require( "../../utils/databaseUtils" );

const state = {
  companyTransactions: []
}

const getters = {
  getCompanyTransactions: state => {
    return state.companyTransactions;
  },
}

const mutations = {
  companyTransactions( state, payload ) {
    state.companyTransactions = payload;
  },
}


const actions = {
  fetchMyCompanyTransactions( {
    commit
  } ) {
    console.log( 'Ready to fetch active Company transactions' );
    let myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
    firebase.firestore().collection( databaseUtils.LIVE_TRANSACTIONS )
      .where( 'companyID', '==', myActiveCompany.id ).get().then( ( querySnapshot ) => {

        let companyTransactions = [];
        querySnapshot.forEach( ( doc ) => {
          companyTransactions.push( doc.data() )
        } );

        console.log( 'companyTransactions', companyTransactions );
        if ( companyTransactions !== undefined ) {
          commit( 'companyTransactions', companyTransactions );
        }
      } );
  },

  topUpCredit( {
    commit,
    dispatch
  }, payload ) {
    console.log( 'Ready to fetch active Company transactions' );
    let myActiveCompany = JSON.parse( localStorage.getItem( 'activeCompany' ) );
    let random = Math.random().toString();
    payload[ "date" ] = new Date().getTime();
    let transactioID = crypto.createHash( 'sha1' ).update( payload.clientID + payload.date + payload.companyID + random ).digest( 'hex' );
    let user = JSON.parse( localStorage.getItem( 'user' ) );
    payload[ "clientID" ] = user.userID;
    payload[ "companyID" ] = myActiveCompany.id;
    payload[ "type" ] = "CREDIT";
    payload[ "status" ] = "PENDING";
    payload[ "id" ] = transactioID;
    payload.gross = parseFloat( payload.gross );

    console.log( payload );

    firebase.firestore().collection( databaseUtils.LIVE_TRANSACTIONS )
      .doc( payload.id ).set( payload ).then( ref => {
        console.log( 'Added COMPANY with ID: ', payload.id );
        dispatch( "fireMessageAlert", {
          type: "success",
          title: "Success",
          body: 'Transaction Request has been submited'
        } );
      } );
  },
}
export default {
  state,
  getters,
  mutations,
  actions,
}