<template>
  <div class="h-100">
    <router-view />
    <notifications group="foo" />
  </div>
</template>

<script>
export default {};
</script>
