import {
  firebase
} from '@firebase/app';
import '@firebase/firestore'
import '@firebase/auth';
import '@firebase/database';
import '@firebase/storage';

export const usersPaths = "/SYSTEM/USERS/usersLight";
const databaseUtils = require( "../../utils/databaseUtils" );

export default {
  state: {
    currentUser: localStorage.getItem( 'user' ) != null ? JSON.parse( localStorage.getItem( 'user' ) ) : null,
    loginError: null,
    processing: false,
    forgotMailSuccess: null,
    resetPasswordSuccess: null,
    messageAlert: null,
  },
  getters: {
    currentUser: state => state.currentUser,
    processing: state => state.processing,
    loginError: state => state.loginError,
    forgotMailSuccess: state => state.forgotMailSuccess,
    resetPasswordSuccess: state => state.resetPasswordSuccess,
    messageAlert: state => state.messageAlert,
  },
  mutations: {
    setUser( state, payload ) {
      state.currentUser = payload
      state.processing = false
      state.loginError = null
    },
    setLogout( state ) {
      state.currentUser = null
      state.processing = false
      state.loginError = null
    },
    setProcessing( state, payload ) {
      state.processing = payload
      state.loginError = null
    },
    setError( state, payload ) {
      state.loginError = payload
      state.currentUser = null
      state.processing = false
    },
    setForgotMailSuccess( state ) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.forgotMailSuccess = true
    },
    setResetPasswordSuccess( state ) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.resetPasswordSuccess = true
    },
    clearError( state ) {
      state.loginError = null
    },
    setAlert( state, payload ) {
      state.messageAlert = payload
      state.processing = false
    },
    clearAlert( state ) {
      state.messageAlert = null
    },
  },
  actions: {
    fireMessageAlert( {
      commit
    }, message ) {
      commit( 'setAlert', message )
      setTimeout( () => {
        commit( 'clearAlert' )
      }, 3000 );
    },

    login( {
      commit,
      dispatch
    }, payload ) {
      commit( 'clearError' )
      commit( 'setProcessing', true )
      firebase
        .auth()
        .signInWithEmailAndPassword( payload.email, payload.password )
        .then(
          userCredential => {
            firebase.firestore().collection( databaseUtils.PROFILES ).doc( userCredential.user.uid )
              .get().then( snapshot => {
                if ( snapshot.exists ) {
                  let userItem = snapshot.data();
                  console.log( userItem );
                  localStorage.setItem( 'user', JSON.stringify( userItem ) )
                  commit( 'setUser', {
                    uid: userCredential.user.uid,
                    ...userItem
                  } )
                } else {
                  // Signed in 
                  let currentUser = {
                    email: payload.email,
                  }
                  var user = userCredential.user;
                  console.log( user );
                  // ...userName
                  var uid = user.uid;
                  currentUser.userID = uid;
                  currentUser.imageURL = "https://firebasestorage.googleapis.com/v0/b/yellowbird-freelancer.appspot.com/o/webimages%2Fapi%2Faccounts%2FScreen%20Shot%202021-03-16%20at%203.12.16%20PM.png?alt=media&token=afdcd17a-939f-4e0d-83bc-446e31e2c7c5";

                  firebase.firestore().collection( databaseUtils.PROFILES ).doc( currentUser.userID )
                    .set( currentUser ).then( function () {
                      console.log( "Document successfully updated!" );
                      localStorage.setItem( 'user', JSON.stringify( currentUser ) )
                      commit( 'setUser', {
                        uid: user.uid,
                        ...currentUser
                      } )
                    } )
                    .catch( function ( error ) {
                      console.error( "Error updating document: ", error );
                      commit( 'setError', "Error adding your account." )
                      setTimeout( () => {
                        commit( 'clearError' )
                      }, 3000 )
                    } );
                }
              } );
          },
          err => {
            console.log( err )
            localStorage.removeItem( 'user' )
            commit( 'setError', err.message )
            setTimeout( () => {
              commit( 'clearError' )
            }, 3000 );
          }
        )
    },

    registerUser( {
      commit
    }, currentUser ) {
      firebase.auth().createUserWithEmailAndPassword( currentUser.email, currentUser.password )
        .then( ( userCredential ) => {
          // Signed in 
          var user = userCredential.user;
          console.log( user );
          // ...userName
          var uid = user.uid;
          currentUser.userID = uid;
          currentUser.imageURL = "https://firebasestorage.googleapis.com/v0/b/yellowbird-freelancer.appspot.com/o/webimages%2Fapi%2Faccounts%2FScreen%20Shot%202021-03-16%20at%203.12.16%20PM.png?alt=media&token=afdcd17a-939f-4e0d-83bc-446e31e2c7c5";

          firebase.firestore().collection( databaseUtils.PROFILES ).doc( currentUser.userID )
            .set( currentUser ).then( function () {
              console.log( "Document successfully updated!" );
              localStorage.setItem( 'user', JSON.stringify( currentUser ) )
              commit( 'setUser', {
                uid: user.uid,
                ...currentUser
              } )
            } )
            .catch( function ( error ) {
              console.error( "Error updating document: ", error );
              commit( 'setError', "Error adding your account." )
              setTimeout( () => {
                commit( 'clearError' )
              }, 3000 )
            } );
        } )
        .catch( ( error ) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          // ..
          console.error( "Error updating document: ", errorMessage );
          commit( 'setError', errorMessage )
          setTimeout( () => {
            commit( 'clearError' )
          }, 3000 )
        } );
    },

    forgotPassword( {
      commit,
      dispatch
    }, payload ) {
      commit( 'clearError' )
      commit( 'setProcessing', true )
      firebase
        .auth()
        .sendPasswordResetEmail( payload.email )
        .then(
          user => {
            commit( 'clearError' )
            commit( 'setForgotMailSuccess' )
            dispatch( "fireMessageAlert", {
              type: "success",
              title: "Success",
              body: `Password reset email has been sent.`
            } );
          },
          err => {
            commit( 'setError', err.message )
            setTimeout( () => {
              commit( 'clearError' )
            }, 3000 )
          }
        )
    },

    resetPassword( {
      commit,
      dispatch
    }, payload ) {
      commit( 'clearError' )
      commit( 'setProcessing', true )
      firebase
        .auth()
        .confirmPasswordReset( payload.resetPasswordCode, payload.newPassword )
        .then(
          user => {
            commit( 'clearError' )
            commit( 'setResetPasswordSuccess' )
            dispatch( "fireMessageAlert", {
              type: "success",
              title: "Success",
              body: `Password reset email has been sent.`
            } );
          },
          err => {
            commit( 'setError', err.message )
            setTimeout( () => {
              commit( 'clearError' )
            }, 3000 )
          }
        )
    },

    /*
       return await auth.(resetPasswordCode, newPassword)
        .then(user => user)
        .catch(error => error);
    */
    handleResetPassword( {
      commit,
      dispatch
    }, payload ) {
      // Localize the UI to the selected language as determined by the lang
      // parameter.

      // Verify the password reset code is valid.
      firebase.auth()
        .verifyPasswordResetCode( payload.actionCode )
        .then( ( email ) => {
          var accountEmail = email;

          // Save the new password.
          firebase.auth()
            .confirmPasswordReset( payload.actionCode, payload.newPassword )
            .then( ( resp ) => {
              // Password reset has been confirmed and new password updated.

              // TODO: Display a link back to the app, or sign-in the user directly
              // if the page belongs to the same domain as the app:
              // auth.signInWithEmailAndPassword(accountEmail, newPassword);

              // TODO: If a continue URL is available, display a button which on
              // click redirects the user back to the app via continueUrl with
              // additional state determined from that URL's parameters.
              dispatch( "fireMessageAlert", {
                type: "success",
                title: "Success",
                body: `Password reset has been confirmed and new password updated.`
              } );
            } ).catch( ( error ) => {
              // Error occurred during confirmation. The code might have expired or the
              // password is too weak.
            } );
        } ).catch( ( error ) => {
          // Invalid or expired action code. Ask user to try to reset the password
          // again./
          dispatch( "fireMessageAlert", {
            type: "error",
            title: "Error",
            body: `Error: ${error}`
          } );
        } );
    },

    handleVerifyEmail( {
      commit,
      dispatch
    }, payload ) {
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      // Try to apply the email verification code.
      firebase.auth()
        .applyActionCode( payload.actionCode ).then( ( resp ) => {
          // Email address has been verified.

          // TODO: Display a confirmation message to the user.
          // You could also provide the user with a link back to the app.

          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
          dispatch( "fireMessageAlert", {
            type: "success",
            title: "Success",
            body: `Email address has been verified.`
          } );
        } ).catch( ( error ) => {
          // Code is invalid or expired. Ask the user to verify their email address
          // again.
          dispatch( "fireMessageAlert", {
            type: "error",
            title: "Error",
            body: `Error: ${error}`
          } );
        } );
    },

    handleRecoverEmail( {
      commit,
      dispatch
    }, payload ) {
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      var restoredEmail = null;
      // Confirm the action code is valid.
      firebase.auth()
        .checkActionCode( payload.actionCode ).then( ( info ) => {
          // Get the restored email address.
          restoredEmail = info[ 'data' ][ 'email' ];

          // Revert to the old email.
          return firebase.auth()
            .applyActionCode( payload.actionCode );
        } ).then( () => {
          // Account email reverted to restoredEmail

          // TODO: Display a confirmation message to the user.

          // You might also want to give the user the option to reset their password
          // in case the account was compromised:
          dispatch( "fireMessageAlert", {
            type: "success",
            title: "Success",
            body: `Account email reverted to restoredEmail.`
          } );
          firebase.auth()
            .sendPasswordResetEmail( restoredEmail ).then( () => {
              // Password reset confirmation sent. Ask user to check their email.
              dispatch( "fireMessageAlert", {
                type: "success",
                title: "Success",
                body: `Password reset confirmation sent. Check your email.`
              } );
            } ).catch( ( error ) => {
              // Error encountered while sending password reset code.
              dispatch( "fireMessageAlert", {
                type: "error",
                title: "Error",
                body: `Error: ${error}`
              } );
            } );
        } ).catch( ( error ) => {
          // Invalid code.
          dispatch( "fireMessageAlert", {
            type: "error",
            title: "Error",
            body: `Error: ${error}`
          } );
        } );
    },
    /*
       return await auth.(resetPasswordCode, newPassword)
        .then(user => user)
        .catch(error => error);
    */
    signOut( {
      commit
    } ) {
      firebase
        .auth()
        .signOut()
        .then( () => {
          localStorage.removeItem( 'user' )
          localStorage.removeItem( 'activeCompany' )
          commit( 'setLogout' )
        }, _error => {} )
    }
  }
}