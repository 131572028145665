import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

import Notifications from 'vue-notification'

// BootstrapVue add
import BootstrapVue from 'bootstrap-vue'
// Router & Store add
import store from './store'
import {
  firebaseConfig
}
  from './constants/config'
import firebase from 'firebase/app'
import 'firebase/auth'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VuePapaParse from 'vue-papa-parse'

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(Notifications)
Vue.use(VueAxios, axios)
firebase.initializeApp(firebaseConfig);
import VueHighlightJS from 'vue-highlight.js';

// Highlight.js languages (Only required languages)
import css from 'highlight.js/lib/languages/css';
import javascript from 'highlight.js/lib/languages/javascript';
import vue from 'vue-highlight.js/lib/languages/vue';
// Highlight.js languages (All languages)
import 'vue-highlight.js/lib/allLanguages'

/*
* Import Highlight.js theme
* Find more: https://highlightjs.org/static/demo/
*/
import 'highlight.js/styles/default.css';

/*
* Use Vue Highlight.js
*/
Vue.use(VueHighlightJS);

Vue.use(VuePapaParse)

Vue.config.productionTip = false

import * as VueGoogleMaps from 'vue2-google-maps'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDiv_fPRz1TprudoXsn6U0xwGSRHmC-xOM',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

Vue.use(VuetifyGoogleAutocomplete, {
  /*
    not used as loaded with component
    apiKey: key,
  */
  vueGoogleMapsCompatibility: true,
});

let theme;
if (localStorage.getItem("theme") == "light") {
  theme = false;
} else {
  theme = true;
}

export default new Vue({
  router,
  store,
  vuetify: new Vuetify({
    theme: {
      dark: theme,
      themes: {
        dark: {
          primary: "#007bff",
          secondary: "#333",
          accent: "#37474F",
        },
        light: {
          primary: '#3f51b5',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          error: '#f44336'
        },
      },
    },
  }),
  render: h => h(App),
}).$mount('#app')