export const COMPANIES = '/APIS/PROFILES/COMPANIES/';
export const PROFILES = '/APIS/PROFILES/PROFILES/';
export const COMPANY_USERS = '/APIS/PROFILES/COMPANY_USERS/';
export const API_KEYS = '/APIS/PROFILES/API_KEYS/';
export const LIVE_TRANSACTIONS = '/APIS/TRANSACTIONS/LIVE_TRANSACTIONS/';
export const SANDBOX_TRANSACTIONS = '/APIS/TRANSACTIONS/SANDBOX_TRANSACTIONS/';
export const REQUESTS = '/APIS/TRANSACTIONS/REQUESTS/';
export const REPORTING = '/APIS/TRANSACTIONS/REPORTING/';
export const VEHICLE_TYPES = '/APIS/SETTINGS/VEHICLE_TYPES';

export const apiURL = "https://app.yellowbird.ug/api/";

import firebase from 'firebase'

export const uploadFileToFirebase = (
  fileData, //: string,
  fileName, //: string,
  fileType, //: 'PROFILE' | 'GALLERY' | 'OTHERS',
) => {
  return new Promise( ( resolve, reject ) => {

    let fileLocation;
    if ( fileType === 'PROFILE' )
      fileLocation = `SYSTEM/PROFILE_PICTURES/${fileName}`
    else if ( fileType === 'GALLERY' )
      fileLocation = `SYSTEM/GALLERY/${fileName}`
    else if ( fileType === 'OTHERS' )
      fileLocation = `SYSTEM/OTHERS/${fileName}`
    else
      fileLocation = `SYSTEM/IMAGES/${fileName}`

    let storageRef = firebase.storage().ref( fileLocation );

    let uploadTask = storageRef.putString( fileData, 'data_url' );

    uploadTask.on( firebase.storage.TaskEvent.STATE_CHANGED, ( snapshot ) => {
      let progress = ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100;

    }, error => {
      reject( error.message );

    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then( downloadURL => {
        resolve( downloadURL )
      } );
    } )
  } );
};

export const getShortDate = ( dateTimeMillis ) => {
  var a = new Date( dateTimeMillis );
  var months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
  var year = a.getFullYear();
  var month = months[ a.getMonth() ];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
  return time;
};

export const getMonday = ( d ) => {
  d = new Date( d );
  var day = d.getDay(),
    diff = d.getDate() - day + ( day == 0 ? -6 : 1 ); // adjust when day is sunday
  var mondayDate = new Date( d.setDate( diff ) );

  var year = mondayDate.getFullYear();
  var month = mondayDate.getMonth() + 1;
  var mDay = mondayDate.getDate();
  if ( month < 10 ) {
    month = "0" + month;
  }
  if ( mDay < 10 ) {
    mDay = "0" + mDay;
  }
  return year + "-" + month + "-" + mDay;
};

export const getMonthFirst = ( date ) => {
  var mondayDate = new Date( date.getFullYear(), date.getMonth(), 1 );
  var year = mondayDate.getFullYear();
  var month = mondayDate.getMonth() + 1;
  var day = mondayDate.getDate();
  if ( month < 10 ) {
    month = "0" + month;
  }
  if ( day < 10 ) {
    day = "0" + day;
  }
  return year + "-" + month + "-" + day;
};

export const getDateString = ( d ) => {
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var day = d.getDate();
  if ( month < 10 ) {
    month = "0" + month;
  }
  if ( day < 10 ) {
    day = "0" + day;
  }
  return year + "-" + month + "-" + day;
}

export const secondsToHms = ( d ) => {
  d = Number( d );
  var h = Math.floor( d / 3600 );
  var m = Math.floor( d % 3600 / 60 );
  var s = Math.floor( d % 3600 % 60 );

  var hDisplay = h > 0 ? h + ( h == 1 ? " hr, " : " hrs, " ) : "";
  var mDisplay = m > 0 ? m + ( m == 1 ? " min, " : " mins, " ) : "";
  var sDisplay = s > 0 ? s + ( s == 1 ? " sec" : " secs" ) : "";
  return hDisplay + mDisplay + sDisplay;
}

export const timeConversion = ( millisec ) => {
  return ( millisec / ( 1000 * 60 * 60 ) ).toFixed( 1 ) + "Hrs";
}

export const onCurrentWeek = ( date ) => {
  const WEEK_LENGTH = 604800000;
  var lastMonday = new Date(); // Creating new date object for today
  lastMonday.setDate( lastMonday.getDate() - ( lastMonday.getDay() - 1 ) ); // Setting date to last monday
  lastMonday.setHours( 0, 0, 0, 0 ); // Setting Hour to 00:00:00:00

  const res = lastMonday.getTime() <= date.getTime() &&
    date.getTime() < ( lastMonday.getTime() + WEEK_LENGTH );
  return res; // true / false
}

export const getShortDateAndTime = ( dateTimeMillis ) => {
  var a = new Date( dateTimeMillis );
  var months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
  var year = a.getFullYear();
  var month = months[ a.getMonth() ];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
  return time;
}

export const generateDbPathFromList = ( listOfStrings ) => {

  let path = '';
  listOfStrings.forEach( ( id ) => {
    if ( path == '' )
      path = id;
    else path = path + '-' + id;
  } )

  return filterPath( path );
}

export const filterPath = ( key ) => {
  key = key.replace( " ", "-" );
  key = key.replace( "/", "-" );
  key = key.replace( "//", "-" );
  key = key.replace( "/", "-" );
  key = key.replace( "\\", "-" );
  key = key.replace( "_", "-" );
  key = key.replace( "--", "-" );
  key = key.replace( "- -", "-" );
  key = key.replace( ",,", "," );
  key = key.replace( ".", "-" );
  key = key.replace( ".", "-" );
  key = key.replace( "[", "-" );
  key = key.replace( "]", "-" );
  key = key.replace( "{", "-" );
  key = key.replace( "}", "-" );
  key = key.replace( "(", "-" );
  key = key.replace( ")", "-" );
  key = key.replace( "$", "-" );
  key = key.replace( "&", "-" );
  key = key.replace( "'", "-" );
  key = key.replace( "\"", "-" );
  key = key.replace( "#", "-" );
  key = key.replace( "-null", "" );
  key = key.replace( "null-", "" );
  key = key.replace( "null_", "" );
  key = key.replace( "_null", "" );
  key = key.replace( "0x00", "-" );
  key = key.replace( "0x1F", "-" );
  key = key.replace( "0x7F", "-" );
  if ( key.length >= 768 )
    key = key.substring( 0,
      500 ); //768 is the max number of character a downloadPath can contain.
  return key;
}